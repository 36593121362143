































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { getTranslations } from '@/utils'
import { createFilter, getFilterItems } from '@/utils/filter'

@Component({
  name: 'MultiSelectFilter'
})

export default class extends Vue {
  @Prop({ required: true }) private filter!: any
  private list = []
  private isFieldLoading = false
  private listQuery = createFilter()

  created() {
    if (!this.filter.lazy) {
      if (this.filter.list) {
        this.list = this.filter.list
      } else {
        this.requestForData()
      }
    }
  }

  private optionLabel(item: any) {
    if (this.filter.customLabel) {
      return this.filter.customLabel(item)
    }

    if (this.filter.itemText) {
      const itemText = Array.isArray(this.filter.itemText)
        ? this.filter.itemText
        : [this.filter.itemText]
      return getTranslations(item, itemText)
    }

    return getTranslations(item)
  }

  private onChange() {
    this.$emit('value-changed')
  }

  private prepareFilters(search: string) {
    if (!this.listQuery.query) return

    if (this.listQuery.query?.filters) {
      this.listQuery.query.filters.items = [...getFilterItems([], {
        value: search,
        fields: this.filter.searchFields || []
      })]
    }
  }

  private async querySearch(newVal: string) {
    if (this.isFieldLoading || newVal.length < 3) return
    this.isFieldLoading = true
    try {
      this.prepareFilters(newVal)
      const { data } = await this.filter.apiEndpoint(this.listQuery)
      this.list = data.collection || []
    } catch (e) {}
    this.isFieldLoading = false
  }

  private async requestForData() {
    try {
      if (!this.listQuery.query || !this.listQuery.query.pagination) return
      this.listQuery.query.pagination.fetch_all = true
      const { data } = await this.filter.apiEndpoint(this.listQuery)
      this.list = data.collection || []
    } catch (e) {}
  }
}
