





















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'SingleSelectFilter'
})

export default class extends Vue {
  @Prop({ required: true }) private filter!: any

  private getLabel(item: any) {
    item = this.filter.lowerCase ? item.toLowerCase() : item
    return this.$t(`${this.filter.transKey}.${item}`)
  }

  private onChange() {
    this.$emit('value-changed')
  }
}
