




























































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import SingleSelectFilter from './SingleSelectFilter.vue'
import DateRangeFilter from './DateRangeFilter.vue'
import SwitchStateFilter from './SwitchStateFilter.vue'
import StackedFieldsFilter from './StackedFieldsFilter.vue'
import SearchBoxFilter from './SearchBoxFilter.vue'
import MultiSelectFilter from './MultiSelectFilter.vue'
import { AppModule, DeviceType } from '@/store/modules/app'
import { clearFilters } from '@/utils/filter'
const cloneDeep = require('clone-deep')

@Component({
  name: 'FiltersDrawer',
  components: {
    SingleSelectFilter,
    DateRangeFilter,
    SwitchStateFilter,
    StackedFieldsFilter,
    MultiSelectFilter,
    SearchBoxFilter
  }
})

export default class extends Vue {
  @Prop({ required: true }) private show!: boolean
  @Prop({ required: true }) private filters!: any
  @Prop({ required: true }) private count!: number
  @Prop({ default: 45 }) private size!: number
  @Prop({ default: 1 }) private colsPerRow!: number
  private drawer = false;
  private col = 24;
  private internalFilters: any = {}

  get drawerSize() {
    if (AppModule.device === DeviceType.Desktop) {
      return this.size
    }
    return 100
  }

  private handleClose() {
    this.$emit('close')
  }

  private submitFilters() {
    this.$emit('submit-filters', this.internalFilters)
    this.handleClose()
  }

  private beforeOpen() {
    this.internalFilters = cloneDeep(this.filters)
  }

  created() {
    this.col = 24 / this.colsPerRow
  }

  private clear() {
    clearFilters(this.internalFilters)
    this.onFilterChange()
  }

  private onFilterChange() {
    this.$emit('filters-changed', this.internalFilters)
  }

  @Watch('show')
  onShowChanged(val: boolean) {
    this.drawer = val
  }
}
