


















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'SearchBoxFilter'
})

export default class extends Vue {
  @Prop({ required: true }) private filter!: object
}
