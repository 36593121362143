

































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'DateRangeFilter'
})

export default class extends Vue {
  @Prop({ required: true }) private filter!: object

  private onChange() {
    this.$emit('value-changed')
  }
}
