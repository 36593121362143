






























































import {
  Component,
  Prop,
  Vue,
  Watch
} from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue'

@Component({
  name: 'ListToolbar',
  components: {
    Pagination
  }
})

export default class extends Vue {
  @Prop({ required: true }) private title!: string
  @Prop({ default: true }) private withSearch!: boolean
  @Prop({ default: false }) private withPagination!: boolean
  @Prop({ default: false }) private hasSelected!: boolean
  @Prop({ default: 0 }) private total!: number
  @Prop({ required: false }) private listQuery!: any

  private search = ''

  @Watch('search')
  onSearchChanged() {
    this.$emit('value-changed', this.search)
  }
}
